import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const agenzia: React.FC = () => {
  return (
    <Layout>
      <>
        <Seo title="Agenzia - Casa Dalmine" description="..." />
        {/* IMAGE TITLE PARALLAX */}
        <div
          className="flex items-center justify-center py-[115px] m-auto bg-fixed bg-center bg-cover custom-img"
          style={{
            backgroundImage:
              'url(http://1.bp.blogspot.com/-DCD75x5zT-w/VL4LO3zA8oI/AAAAAAAALAQ/hQhedQ8393A/s1600/Bergamo-colle-alto1.jpg)',
          }}
        >
          <div className="absolute w-full object-cover bg-black bg-opacity-50">
            <div className="max-w-7xl mx-auto px-6 py-24">
              <h1 className="mx-auto text-4xl font-semibold text-white">Agenzia</h1>
            </div>
          </div>
        </div>

        <br />

        {/* IMAGE TITLE */}
        <div className="relative flex items-center w-full py-28 overflow-hidden">
          <img
            className="absolute h-full w-full object-cover"
            src="http://1.bp.blogspot.com/-DCD75x5zT-w/VL4LO3zA8oI/AAAAAAAALAQ/hQhedQ8393A/s1600/Bergamo-colle-alto1.jpg"
            alt=""
          />
          <div className="absolute w-full object-cover bg-black bg-opacity-50">
            <div className="max-w-7xl mx-auto px-6 py-28">
              <h1 className="mx-auto text-4xl font-semibold text-white">Agenzia</h1>
            </div>
          </div>
        </div>

        {/* SIMPLE TITLE */}
        <div className="max-w-7xl mx-auto px-6 py-12">
          <h1 className="mx-auto text-4xl font-semibold">Agenzia</h1>
        </div>

        {/* TEXT IMAGE */}
        <div className="max-w-7xl mx-auto px-6 py-12">
          <h2 className="mx-auto mb-10 text-2xl font-semibold text-red-600">CasaDalmine s.r.l.</h2>
          <div className="flex flex-col md:flex-row space-y-12 md:space-y-0 md:space-x-12">
            <div className="w-2/3">
              <div className="text-gray-600">
                <p className="mb-4">
                  Casa Dalmine nasce da una profonda esperienza nel settore dell'intermediazione e
                  locazione immobiliare.
                </p>
                <p className="mb-4">
                  La conoscenza maturata in oltre 30 anni e la professionalità del team ci
                  consentono di garantire un servizio di qualità.
                </p>
                <p className="mb-4">
                  Poniamo grande attenzione ad ogni esigenza del cliente, gestendo con la massima
                  trasparenza tutte le fasi della compravendita, dalla stima iniziale dell'immobile
                  fino alla conclusione della vendita.
                </p>
                <p className="mb-4">
                  Forniamo inoltre assistenza per la soluzione di ogni eventuale problematica
                  tecnica o fiscale.
                </p>
                <p className="mb-4">
                  <b>CasaDalmine... Competenza... Continuità di risultati</b>
                </p>
              </div>
            </div>
            <div className="w-1/3 text-center">
              <img
                className="mx-auto rounded"
                src="http://casadalmine.it/assets/images/chisiamo.jpg"
                alt=""
              />
              <div className="mt-6">
                <h3 className="text-xl font-semibold mb-2">Uffici di Casa Dalmine</h3>
                <p className="text-sm">
                  Via Manzoni, 55
                  <br />
                  24044 Dalmine (BG)
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default agenzia
